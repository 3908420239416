import "./AppColours.css";
import "./App.css";
import MainDataContext from "./data/MainDataContext";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Header from "./modules/Header/Header";
import Footer from "./modules/Footer/Footer";
import Home from "./pages/Home";
import Farmers from "./pages/Farmers";
import Freds from "./pages/Freds";
import Hsb from "./pages/Hsb";
import Union from "./pages/Union";
import Jobs from "./pages/Jobs";
import Job from "./pages/Job";
function App() {
  return (
    <MainDataContext>
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>

          <Route path="/farmers" exact>
            <Farmers />
          </Route>
          <Route path="/freds" exact>
            <Freds />
          </Route>
          <Route path="/hsb" exact>
            <Hsb />
          </Route>
          <Route path="/union" exact>
            <Union />
          </Route>

          {/* <Route path="/events" exact>
            <Events />
          </Route> */}
          {/* 
          <Route path="/events/:event_id">
            <Event />
          </Route> */}

          {/* <Route path="/regularevents/:event_id">
            <RegularEvent />
          </Route> */}

          {/* <Route path="/pastevents" exact>
            <PastEvents />
          </Route> */}

          {/* <Route path="/drinks">
            <Drinks />
          </Route> */}

          <Route path="/jobs" exact>
            <Jobs />
          </Route>

          <Route path="/jobs/:job_id">
            <Job />
          </Route>
          <Redirect to="/" />
          {/* redirect invalid paths */}
        </Switch>
        <Footer />
      </Router>
    </MainDataContext>
  );
}

export default App;

import React from "react";
import { Link } from "react-router-dom";
import "./HeaderLinks.css";
function HeaderLinks() {
  return (
    <nav className="header-links">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/jobs">Jobs</Link>
        </li>
        <li>
          <Link to="/freds">Freds Ale House</Link>
        </li>
        <li>
          <Link to="/farmers">Farmers Arms</Link>
        </li>
        <li>
          <Link to="/union">Union Inn</Link>
        </li>
        <li>
          <Link to="/hsb">Hennigans Sports Bar</Link>
        </li>

        {/* <li>
          <Link to="/drinks">Drinks</Link>
        </li>*/}
      </ul>
    </nav>
  );
}

export default HeaderLinks;

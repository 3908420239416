import React, { useContext } from "react";
import "./HeaderLogo.css";
import { MainDataContext } from "../../data/MainDataContext";
import { Link } from "react-router-dom";
function HeaderLogo() {
  const companyData = useContext(MainDataContext).mainState.companyData;

  if (companyData.length === 0) {
    return null;
  } else {
    return (
      <Link to="/">
        <div className="header_logo">
          <img src={companyData.logo} alt={companyData.title} />
        </div>
      </Link>
    );
  }

  return "logo";
}

export const MemoizedHeaderLogo = React.memo(HeaderLogo);

export default HeaderLogo;

import React, { useContext } from "react";
// import { MainDataContext } from "../data/MainDataContext";
import JobsList from "../modules/Jobs/JobsList";

function Jobs() {
  return (
    <div className="container">
      <div className="info_column">
        <JobsList />
      </div>
    </div>
  );
}

export default Jobs;

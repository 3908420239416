import React, { useContext } from "react";
// import { MainDataContext } from "../data/MainDataContext";
// import Loading from "../components/Loading/Loading";
import JobsList from "../modules/Jobs/JobsList";
import JobDetail from "../modules/Jobs/JobDetail";

function Job() {
  return (
    <div className="theme_dark">
      <div className="container">
        <div className="detail_column">
          <JobDetail />
        </div>

        <div className="info_column">
          <JobsList />
        </div>
      </div>
    </div>
  );
}

export default Job;

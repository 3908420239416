import React, { useContext } from "react";
import { MainDataContext } from "../../data/MainDataContext";
import ImageContainer from "../../components/image/ImageContainer/ImageContainer";
import EmailLink from "../../components/text/EmailLink/EmailLink";
function Venue({ venueName }) {
  const allVenuesData = useContext(MainDataContext).mainState.venuesData;
  if (allVenuesData.length > 0) {
    const venueData = allVenuesData.filter(
      (venue) => venue.venue_name === venueName
    )[0];
    console.log(venueData);
    return (
      <div className="container">
        <div className="info_column">
          <h3>{venueData.title} </h3>
          <p>{venueData.venue_description}</p>
          <p>{venueData.venue_address_1}</p>
          <p>{venueData.venue_address_2}</p>
          <p>{venueData.venue_postcode}</p>
        </div>
        <div className="info_column">
          <p>{venueData.venue_name}</p>
          <EmailLink
            type="square"
            source={venueData.venue_email}
            linkText={venueData.venue_name}
          />
          <p>{venueData.venue_about}</p>
        </div>
        <div className="info_column">
          <ImageContainer
            type="square"
            source={venueData.venue_image_original}
            alt={venueData.venue_name}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default Venue;

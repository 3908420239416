import React, { useContext } from "react";
import { MainDataContext } from "../data/MainDataContext";
import Email from "../components/text/EmailLink/EmailLink";
function Home() {
  const companyData = useContext(MainDataContext).mainState.companyData;
  if (companyData) {
    return (
      <div className="container">
        <div className="info_column">
          <h3>{companyData.title}</h3>
          <p>{companyData.address_1}</p>
          <p>​{companyData.address_2}</p>
          <p>​{companyData.address_3}</p>
          <p>​{companyData.address_4_postcode}</p>
        </div>
        <div className="info_column">
          <Email source={companyData.email} linkText={companyData.title} />
          <p>​{companyData.telephone}</p>
        </div>
        ​<div className="info_column">{companyData.body} </div>
      </div>
    );
  } else {
    return <div>home page null</div>;
  }
}

export default Home;
